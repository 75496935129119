import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { formatEuroInEstonian } from '@model/language'
import { VehicleBase } from '@api/endpoints/vehicles'
import { FlexContainer } from '@layout/FlexContainer'
import styles from './VehicleMonthlyPaymentShort.module.css'

type Props = {
  vehicle: VehicleBase
  className?: string
}

export const VehicleMonthlyPaymentShort: React.FC<Props> = ({ vehicle }) => {
  const { t } = useTranslation()

  return (
    <FlexContainer
      vertical
      bordered
      justifyContentCenter
      alignItemsCenter
      noGap
      className={styles.vehicleMonthlyPaymentContainer}
    >
      <span className={styles.text}>{t('pages.vehicle.monthlyPaymentFrom')}</span>
      <span className={styles.sum}>{formatEuroInEstonian(vehicle.monthlyPaymentFrom ?? 0)}</span>
    </FlexContainer>
  )
}
