import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { formatEuroInEstonian } from '@model/language'
import { getVehiclePrice, VehicleBase } from '@api/endpoints/vehicles'
import { FlexContainer } from '@layout/FlexContainer'
import styles from './VehiclePrice.module.css'

type Props = Readonly<{
  vehicle: VehicleBase
  align?: 'flex-start' | 'flex-end'
  //className?: string
}>

export const VehiclePrice: React.FC<Props> = ({ vehicle, align = 'flex-start' }) => {
  const { t } = useTranslation()

  return (
    <FlexContainer
      noGap
      vertical
      fitWidth
      className={align === 'flex-start' ? styles.priceContainerLeft : styles.priceContainerRight}
    >
      <span className={styles.price}>{formatEuroInEstonian(getVehiclePrice(vehicle))}</span>
      {vehicle.isVatIncluded && <span className={styles.vatInfo}>{t('global.labels.vatIncluded')}</span>}
    </FlexContainer>
  )
}
